import mainjson from '../config/main';
import GenerateOverlay from '../components/koopkaarten/GenerateOverlay';
import React,{useEffect,useState,useRef}  from "react";

async function openGenerate(subid){
    let ret = {component:<GenerateOverlay id={subid}></GenerateOverlay>};
    return ret;
}

let lastshows = null;

function filterDates(array,data){
    let returnArray = [];
    let id = data.code_show_ids;
    lastshows = id;
    let ids = [];
    
    if (id && id.length > 0) {
        if (typeof id == "string") {
            id = JSON.parse(id);
        }
        for (let i = 0; i < id.length; i++) {
            
            ids.push(parseInt(id[i]));
        }
    }
    
    for (let i = 0; i < array.length; i++) {
        let record = array[i];
        if (ids == null || ids.length == 0 || ids.includes(record.extra.date_show_id)) {
            returnArray.push(record);   
        }
    }
    //console.log("ja, ",data,array)
    
    return returnArray;
}

export default function GetData() {
        return {
        "apitype":"codes",
        "id":"code_id",
        "subidname":"code_code",
        "options":["edit","delete"],
        "extrabuttons":[
            {
                name:"Generate",
                action: openGenerate,
            }
        ],
        "nameSingle":"code",
        "nameMultiple":"codes",
        "filters":
        [
            {
                "key":"used",
                "name":"Used",
                "options":[
                    {key:"0",value:"Not used"},
                    {key:"1",value:"Used"}
                ]
            }
        ]
        ,
        "fields":{
            "field1":{
                "name":"Code",
                "field":"code_code",
                "type":"Display",
                "list":true,
            },
            "field2":{
                "name":"Used",
                "field":"code_used",
                "type":"DropDown",
                "options": {
                    "0":"No",
                    "1":"Yes",
                },
                "edit":true,
                "list":true,
            },    
            "field3":{
                "name":"Type",
                "field":"code_type",
                "type":"DropDown",
                "options": {
                    "percentage":"Percentage",
                    "amount":"Amount",
                },
                "edit":true,
                "list":true,
            },  
            "field4":{
                "name":"Apply to",
                "field":"code_apply_on",
                "type":"DropDown",
                "options": {
                    "ticket":"Ticket",
                    "order":"Order",
                },
                "edit":true,
                "list":true,
            },    
            "field5":{
                "name":"Value (percent or value in cents)",
                "field":"code_value",
                "type":"TextInput",
                "subtype":"number",
                "required":true,
                "list":true,
            },
            "field6":{
                "name":"Description",
                "field":"date_description",
                "type":"TextInput",
                "required":false,
                "list":true,
            },
            "field7":{
                "name":"Shows",
                "field":"code_show_ids",
                "emptyfirstoption":"All shows",
                "emptyfirstoptionvalue":"-1",
                "multiple":true,
                "type":"DropDown",
                "options": "shows",
                "list":false,
                "edit":true,
            }, 
            "field8":{
                "name":"Dates",
                "field":"code_date_ids",
                "emptyfirstoption":"All dates",
                "emptyfirstoptionvalue":"-1",
                "multiple":true,
                "type":"DropDown",
                "options": "dates",
                "list":false,
                "edit":true,
                "filter":filterDates
            }, 

             
        }
    }
}