import React, { useState, useRef, useEffect,useContext,createContext} from "react";
import styled from "styled-components";
import {EditContext} from "../form/Edit";
import {BaseFormField,Button,FormSubmit,FormCancel,BaseSelect} from "../../styles/Global";
import axios from "axios";
import mainjson from '../../config/main.js';
// import { Post } from "../utils/ApiFunctions";
import { GetList,Post } from "../../utils/ApiFunctions";

const BaseFormFieldPadding = styled(BaseFormField).attrs({
})`
 margin-bottom:15px;
`

const Select = styled(BaseSelect)`
height: ${props => props.multiple ? "200px" : "auto"};  
`

const Container = styled.div`
display:inline-block;

`
const Background = styled.div`
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color:rgba(0,0,0,0.5);
    z-index:4000;
`

const InnerContent = styled.div`
   padding:20px;
`

const CenterBlock = styled.div`
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    margin:auto;
    width:400px;
    height:350px;
    background-color:white;
    z-index:4001;
    border-radius:5px;

`

const ListTitle = styled.div`
  line-height: 1.5;
  color: #212529;
  text-align: left;
  font-size: 14px;
  box-sizing: border-box;
  outline: 0;
  display: inline-block;
  margin-bottom: .5rem;
  font-weight: 600;
  display:block;
`;

const Buttons = styled.div`
  position:absolute;
  bottom:20px;
`;

export default function GenerateOverlay(props) {
    const [filterList, setFilterList] = useState([]);
    const {showExtraComponent,setShowExtraComponent,refreshList,setRefreshList} = useContext(EditContext);
    const dropdown = useRef();
    const [value, setValue] = useState("1");
    const [percentage, setPercentage] = useState("100");
    const [shows, setShows] = useState();
    const [loadedShows, setLoadedShows] = useState([]);
    const [generating, setGenerating] = useState(false);

    async function getShows(){
        let shows = await GetList("shows");
        if (shows.records.data) {
            setLoadedShows(shows.records.data);
        }
    }

    useEffect(() => {
        
        getShows();
      }, []);

    function close(){
        console.log("close overlay")
        setShowExtraComponent(false);
    }
    
    function donotclose(e){
        e.stopPropagation();
    }

    function changed(e){   
        setValue(e.target.value);
    }

    function changedSelect(e){   
        console.log("changedselect",e.target.value);
        if (e.target.value == "0" || e.target.value == 0) {
            setShows(null);
        }
        else {
            setShows([parseInt(e.target.value)]);
        }
    }

    function changedPercentage(e){   
        setPercentage(e.target.value);
    }
    
    async function submit(){
        let nr = parseInt(value);
        let postPercentage = parseInt(percentage);
       
        let resultdata = {};
        if (!isNaN(nr) && !isNaN(postPercentage)) {
            setGenerating(true);
            let body = {count:nr,value:postPercentage,shows:shows};
            await axios({
                method: "post",
                url: mainjson().generate_path,
                data: body,
            })
            .then((response) => {
            if (response.data) {
                resultdata = response.data;
            }
            })
            .catch((error) => {
                console.log("error "+error);
            });
        }
        else {
            alert("Velden incorrect");
        }

        if (resultdata && resultdata.codes) {
            alert(resultdata.codes.length+" codes gegenereerd");
            let codes = "";
            for (let i = 0; i < resultdata.codes.length; i++) {
                let code = resultdata.codes[i];
                codes += code + "\n";
            }
            var hiddenElement = document.createElement('a');  
            hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(codes);  
            hiddenElement.target = '_blank';  
            
            //provide the name for the CSV file to be downloaded  
            hiddenElement.download = 'codes.txt';  
            hiddenElement.click();  
        }
        setRefreshList(true);

        close();

    }
     

    return (
       
       <Background onMouseDown={close}>
            <CenterBlock onMouseDown={donotclose}>
            <InnerContent>
                <ListTitle>Number of codes to generate:</ListTitle>
                <BaseFormFieldPadding type='text' onChange={changed} value={value||""}/>
                <ListTitle>Percentage:</ListTitle>
                <BaseFormFieldPadding type='text' onChange={changedPercentage} value={percentage||""}/>
                <ListTitle>Show:</ListTitle>
                <Select onChange={changedSelect} value={shows}>
                    <option value="0">All Shows</option>
                    {loadedShows.map((val, index) => {
                        return <option value={val.show_id} key={val.show_id}>{val.show_name}</option>
                    })}
                </Select>
                <Buttons>
                {!generating && <FormSubmit onClick={submit}>Generate</FormSubmit> } <FormCancel onClick={close}>Cancel</FormCancel>
                </Buttons>
            </InnerContent>
            </CenterBlock>

       </Background>

    );
}